<template>
  <div>
    <v-expansion-panels
      v-model="expanded"
      multiple
      class="mt-4"
    >
      <v-expansion-panel>
        <v-expansion-panel-header>
          Feedback
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table
            dense
            fixed-header
            class="mt-2"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(feedbackHeader, index) in feedbackHeaders"
                    :key="index"
                    class="text-left"
                    :width="feedbackHeader.width"
                  >
                    {{ feedbackHeader.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(feedback) in feedbackList"
                  :key="feedback.id"
                >
                  <td
                    v-for="(feedbackHeader) in feedbackHeaders"
                    :key="`${feedback.id}-${feedbackHeader.value}`"
                    :width="feedbackHeader.width"
                  >
                    <span v-if="feedbackHeader.value === 'createdOnLocal'">
                      {{ feedback[feedbackHeader.value] | displayDateTimeFormat }}
                    </span>
                    <span v-else-if="feedbackHeader.value === 'feeling'">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            :color="resolveFeeling(feedback[feedbackHeader.value]).color"
                            v-on="on"
                          >mdi-emoticon-{{ resolveFeeling(feedback[feedbackHeader.value]).emoticon }}</v-icon>
                        </template>
                        <span>{{ resolveFeeling(feedback[feedbackHeader.value]).label }}</span>
                      </v-tooltip>
                    </span>
                    <span v-else-if="feedbackHeader.value === 'recommendability'">
                      <v-icon
                        :color="redGreenGradient11[feedback[feedbackHeader.value]].color"
                      >mdi-numeric-{{ feedback[feedbackHeader.value] }}-circle</v-icon>
                    </span>
                    <span v-else>
                      {{ feedback[feedbackHeader.value] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Bug Reports
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table
            dense
            fixed-header
            class="mt-2"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    v-for="(bugReportHeader, index) in bugReportHeaders"
                    :key="index"
                    class="text-left"
                    :width="bugReportHeader.width"
                  >
                    {{ bugReportHeader.text }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(bugReport) in bugReportList"
                  :key="bugReport.id"
                >
                  <td
                    v-for="(bugReportHeader) in bugReportHeaders"
                    :key="`${bugReport.id}-${bugReportHeader.value}`"
                    :width="bugReportHeader.width"
                  >
                    <span v-if="bugReportHeader.value === 'createdOnLocal'">
                      {{ bugReport[bugReportHeader.value] | displayDateTimeFormat }}
                    </span>
                    <span v-else-if="bugReportHeader.value === 'url'">
                      <a
                        :href="bugReport[bugReportHeader.value]"
                        target="_blank"
                      >{{ bugReport[bugReportHeader.value] }}</a>
                    </span>
                    <span v-else>
                      {{ bugReport[bugReportHeader.value] }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import moment from 'moment'
import { tenantService } from '@/shared/services'
import { greenRedGradientLight8 } from '@/shared/constants'

export default {
  data () {
    return {
      expanded: [...Array(2).keys()],
      feedbackFeelings: [
        {
          value: 1,
          label: 'Hate',
          color: 'red',
          emoticon: 'angry'
        },
        {
          value: 2,
          label: 'Dislike',
          color: 'orange',
          emoticon: 'confused'
        },
        {
          value: 3,
          label: 'Neutral',
          color: 'amber',
          emoticon: 'neutral'
        },
        {
          value: 4,
          label: 'Like',
          color: 'light-green',
          emoticon: 'happy'
        },
        {
          value: 5,
          label: 'Love',
          color: 'green',
          emoticon: 'excited'
        }
      ],
      feedbackHeaders: [
        {
          text: 'Tenant',
          value: 'createdByTenant',
          width: '200'
        },
        {
          text: 'Created By',
          value: 'createdBy',
          width: '200'
        },
        {
          text: 'Date',
          value: 'createdOnLocal',
          width: '200'
        },
        {
          text: 'Feeling',
          value: 'feeling',
          width: '100'
        },
        {
          text: 'Recommendability (0-10)',
          value: 'recommendability',
          width: '100'
        },
        {
          text: 'Description',
          value: 'description',
          width: '1000'
        }
      ],
      feedbackList: [],
      bugReportHeaders: [
        {
          text: 'Tenant',
          value: 'createdByTenant',
          width: '200'
        },
        {
          text: 'Created By',
          value: 'createdBy',
          width: '200'
        },
        {
          text: 'Date',
          value: 'createdOnLocal',
          width: '200'
        },
        {
          text: 'URL',
          value: 'url',
          width: '500'
        },
        {
          text: 'Screen Dimensions',
          value: 'viewportDimensions',
          width: '200'
        },
        {
          text: 'Description',
          value: 'description',
          width: '1000'
        },
        {
          text: 'Steps',
          value: 'stepsToReproduce',
          width: '1000'
        },
        {
          text: 'Actual Result',
          value: 'actualResult',
          width: '1000'
        },
        {
          text: 'Expected Result',
          value: 'expectedResult',
          width: '1000'
        }
      ],
      bugReportList: []
    }
  },

  computed: {
    redGreenGradient11 () {
      return [
        greenRedGradientLight8[7],
        greenRedGradientLight8[7],
        greenRedGradientLight8[6],
        greenRedGradientLight8[5],
        greenRedGradientLight8[4],
        greenRedGradientLight8[4],
        greenRedGradientLight8[3],
        greenRedGradientLight8[2],
        greenRedGradientLight8[1],
        greenRedGradientLight8[0],
        greenRedGradientLight8[0]
      ]
    }
  },

  created () {
    this.loadFeedback()
    this.loadBugReports()
  },

  methods: {
    resolveFeeling (val) {
      return this.feedbackFeelings.find(x => x.value === val)
    },
    async loadFeedback () {
      await tenantService.getFeedbackList().then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
          this.feedbackList = []
        } else {
          this.feedbackList = resp.map(x => (
            {
              ...x,
              createdOnLocal: moment(this.$_global_parseAsUtc(x.createdOn)).local()
            }
          ))
        }
      })
    },
    async loadBugReports () {
      await tenantService.getBugReportList().then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
          this.bugReportList = []
        } else {
          this.bugReportList = resp.map(x => (
            {
              ...x,
              createdOnLocal: moment(this.$_global_parseAsUtc(x.createdOn)).local()
            }
          ))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
