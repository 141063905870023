var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-expansion-panels",
        {
          staticClass: "mt-4",
          attrs: { multiple: "" },
          model: {
            value: _vm.expanded,
            callback: function($$v) {
              _vm.expanded = $$v
            },
            expression: "expanded"
          }
        },
        [
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _vm._v("\n        Feedback\n      ")
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c("v-simple-table", {
                    staticClass: "mt-2",
                    attrs: { dense: "", "fixed-header": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c("thead", [
                              _c(
                                "tr",
                                _vm._l(_vm.feedbackHeaders, function(
                                  feedbackHeader,
                                  index
                                ) {
                                  return _c(
                                    "th",
                                    {
                                      key: index,
                                      staticClass: "text-left",
                                      attrs: { width: feedbackHeader.width }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(feedbackHeader.text) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.feedbackList, function(feedback) {
                                return _c(
                                  "tr",
                                  { key: feedback.id },
                                  _vm._l(_vm.feedbackHeaders, function(
                                    feedbackHeader
                                  ) {
                                    return _c(
                                      "td",
                                      {
                                        key:
                                          feedback.id +
                                          "-" +
                                          feedbackHeader.value,
                                        attrs: { width: feedbackHeader.width }
                                      },
                                      [
                                        feedbackHeader.value ===
                                        "createdOnLocal"
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "displayDateTimeFormat"
                                                    )(
                                                      feedback[
                                                        feedbackHeader.value
                                                      ]
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          : feedbackHeader.value === "feeling"
                                          ? _c(
                                              "span",
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _c(
                                                                "v-icon",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      color: _vm.resolveFeeling(
                                                                        feedback[
                                                                          feedbackHeader
                                                                            .value
                                                                        ]
                                                                      ).color
                                                                    }
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "mdi-emoticon-" +
                                                                      _vm._s(
                                                                        _vm.resolveFeeling(
                                                                          feedback[
                                                                            feedbackHeader
                                                                              .value
                                                                          ]
                                                                        )
                                                                          .emoticon
                                                                      )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.resolveFeeling(
                                                            feedback[
                                                              feedbackHeader
                                                                .value
                                                            ]
                                                          ).label
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : feedbackHeader.value ===
                                            "recommendability"
                                          ? _c(
                                              "span",
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color:
                                                        _vm.redGreenGradient11[
                                                          feedback[
                                                            feedbackHeader.value
                                                          ]
                                                        ].color
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-numeric-" +
                                                        _vm._s(
                                                          feedback[
                                                            feedbackHeader.value
                                                          ]
                                                        ) +
                                                        "-circle"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    feedback[
                                                      feedbackHeader.value
                                                    ]
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              }),
                              0
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-expansion-panel",
            [
              _c("v-expansion-panel-header", [
                _vm._v("\n        Bug Reports\n      ")
              ]),
              _c(
                "v-expansion-panel-content",
                [
                  _c("v-simple-table", {
                    staticClass: "mt-2",
                    attrs: { dense: "", "fixed-header": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c("thead", [
                              _c(
                                "tr",
                                _vm._l(_vm.bugReportHeaders, function(
                                  bugReportHeader,
                                  index
                                ) {
                                  return _c(
                                    "th",
                                    {
                                      key: index,
                                      staticClass: "text-left",
                                      attrs: { width: bugReportHeader.width }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(bugReportHeader.text) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.bugReportList, function(bugReport) {
                                return _c(
                                  "tr",
                                  { key: bugReport.id },
                                  _vm._l(_vm.bugReportHeaders, function(
                                    bugReportHeader
                                  ) {
                                    return _c(
                                      "td",
                                      {
                                        key:
                                          bugReport.id +
                                          "-" +
                                          bugReportHeader.value,
                                        attrs: { width: bugReportHeader.width }
                                      },
                                      [
                                        bugReportHeader.value ===
                                        "createdOnLocal"
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm._f(
                                                      "displayDateTimeFormat"
                                                    )(
                                                      bugReport[
                                                        bugReportHeader.value
                                                      ]
                                                    )
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                          : bugReportHeader.value === "url"
                                          ? _c("span", [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      bugReport[
                                                        bugReportHeader.value
                                                      ],
                                                    target: "_blank"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      bugReport[
                                                        bugReportHeader.value
                                                      ]
                                                    )
                                                  )
                                                ]
                                              )
                                            ])
                                          : _c("span", [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    bugReport[
                                                      bugReportHeader.value
                                                    ]
                                                  ) +
                                                  "\n                  "
                                              )
                                            ])
                                      ]
                                    )
                                  }),
                                  0
                                )
                              }),
                              0
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }